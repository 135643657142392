
<template>
<div>
  <div v-if="!isLoading" class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Member details</h5>
      <div class="row mb-4 g-4">
        <div class="col-lg-12">
          <div class="card mb-4 overflow-hidden">
            <div class="card-body">
              <div class="row">
                <div class="col-10 col-sm-8">
                  <div class="d-flex align-items-center justify-content-start">
                    <div class="me-4 col-lg-4">
                      <img @click="showProfileImg(item.image)" class="w-100 cursor-pointer"
                         v-if="item.image" :src="absoluteUrl(item.image)" />
                    </div> 
                  </div>
                </div>
                <div class="col-2 col-sm-4 text-end">
                  <div class="dropdown dropstart no-arrow">
                    <button class="dropdown-toggle btn btn-sm btn-light" type="button"
                        role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="ri-more-2-fill"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a class="dropdown-item" :href="absoluteUrl('/register')" type="button">
                          Register Member
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row mb-4">
                    <div class="col-12">
                      <div class="row pt-3 g-2">
                        <div class="col-12"> 
                          <span>Full name</span>
                          <h6> {{item.last_name}}, {{item.first_name}} {{item.middle_name}} </h6>
                        </div>
                        <div class="col-sm-6"> 
                            <span>Phone number</span>
                            <h6> {{item.mobile}} </h6>
                        </div> 
                        <div class="col-sm-6"> 
                            <span>NIN</span>
                            <h6> {{item.nin}} </h6>
                        </div>
                        <div class="col-sm-6"> 
                            <span>VIN</span>
                            <h6> {{item.vin}} </h6>
                        </div>
                        <div class="col-sm-6"> 
                            <span>Account name</span>
                            <h6> {{item.account_name}} </h6>
                        </div>
                        <div class="col-sm-6"> 
                            <span>Account number</span>
                            <h6> {{item.account_number}} </h6>
                        </div>
                        <div v-if="item.bank" class="col-sm-6"> 
                            <span>Bank</span>
                            <h6> {{item.bank.name}} </h6>
                        </div>
                        <div class="col-sm-6">
                          <span>Residential Address</span>
                          <div style="word-break: break-word;" v-if="item.residential_address" class="fw-bold">
                            <span class="">{{ item.residential_address.street_address }}</span> <br />
                            <span class="" v-if="item.residential_address.lga">{{ item.residential_address.lga.name }}</span>,
                            <span class="" v-if="item.residential_address.state">{{ item.residential_address.state.name }}</span>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <span>Polling Unit Address</span>
                          <div style="word-break: break-word;" v-if="item.home_address" class="fw-bold">
                            <span class="" v-if="item.home_address.unit">{{ item.home_address.unit.name }}</span> <br />
                            <span class="" v-if="item.home_address.ward">{{ item.home_address.ward.name }}</span>,
                            <span class="" v-if="item.home_address.lga">{{ item.home_address.lga.name }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else />
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'

export default {
  name: "member-show",
  components:{
    IsLoading,
  },
  data() {
    return {
      isLoading: true,
      item: null
    }
  },
  computed:{
    activeUser(){
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    fetchItem(){
      this.isLoading = true
      this.$http.get(`/members/${this.$route.params.memberId}/show`)
      .then((response) => {
        this.isLoading =  false
        if(response.data.success){
          this.item = response.data.data
        }else{
          this.$router.push({path: "/error-404"})
        }
      })
    },
    initializeAll(){
    }
  },
  created(){
    this.fetchItem()
  },
}

</script>

