<template>
  <div class="inner-container">
    <div class="inner-load d-flex align-items-center justify-content-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  export default {
    props:{
      box: {
        default: false
      }
    }
  }
  </script>
  
  <style>
  .loading-viewport{
    position: relative;
  }
  .loading-viewport .inner-container{
    background: rgb(243 243 249 / 50%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
  .loading-viewport .inner-container .inner-load{
    height: 100%;
    min-height: 100%;
  }
  </style>